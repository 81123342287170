/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

/** @namespace GdprCookie/Plugin/App/getStoreConfigFields */
export const _getStoreConfigFields = (args, callback) => ([
    ...callback(...args),
    'gdpr_cookie_bar'
]);

export default {
    'Query/Config': {
        'member-function': {
            _getStoreConfigFields
        }
    }
};
