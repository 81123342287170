/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { ChildrenType } from 'Type/Common';

import './PrivacyPolicyButton.style.scss';

/** @namespace Gdpr/Component/PrivacyPolicyButton/Component/PrivacyPolicyButtonComponent */
export class PrivacyPolicyButtonComponent extends PureComponent {
    static propTypes = {
        children: ChildrenType,
        onButtonClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        children: null
    };

    render() {
        const { children, onButtonClick } = this.props;

        return (
            <button
              block="PrivacyPolicyButton"
              mix={ { block: 'Button' } }
              onClick={ onButtonClick }
            >
                { children }
            </button>
        );
    }
}

export default PrivacyPolicyButtonComponent;
